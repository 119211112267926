import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';

import { SITE_TYPES } from '../../core/constants';
import ConfirmationModal from '../../minted-components-ports/ConfirmationModal';

const modalBodyStyles = css`
    list-style-position: inside;
    padding-left: 0;
`;

const DeleteSiteModal = ({
  close,
  domainIsCustom,
  isOpen,
  onDeleteSite,
  siteType,
}) => {
  const onSubmit = useCallback(()=> {
    onDeleteSite();
    close();
  }, [
    close,
    onDeleteSite,
  ]);

  let warningText = '';

  if (siteType === SITE_TYPES.WEDDING) {
    warningText = 'Deleting your wedding website will not remove your account on Minted. Are you sure you want to delete your wedding website?';
  } else if (siteType === SITE_TYPES.HOLIDAY) {
    warningText = 'Deleting your website will remove all of your content and photos and your card recipients will no longer be able to visit your site. It will not remove your account on Minted. Are you sure you want to delete your website?';
  } else if (siteType === SITE_TYPES.ONLINE_INVITATION) {
    // NJ TODO: What is the warning displayed when deleting an online invitation site
  }

  return (
    <ConfirmationModal
      isDeleteModal={true}
      onCloseClick={close}
      onSubmit={onSubmit}
      open={isOpen}
    >
      {
        siteType === SITE_TYPES.WEDDING && (
          <Fragment>
            <div>
              Deleting your wedding website will:
            </div>
            <ul
              css={modalBodyStyles}
            >
              <li>
                Remove all of your content and photos.
              </li>
              {
                !domainIsCustom && (
                  <li>
                    Release your URL so others can use it.
                  </li>
                )
              }
              <li>
                Remove guest lists and all RSVP responses.
              </li>
            </ul>
          </Fragment>
        )
      }
      <div>
        { warningText }
      </div>
    </ConfirmationModal>
  );
};

DeleteSiteModal.propTypes = {
  close: PropTypes.func.isRequired,
  domainIsCustom: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDeleteSite: PropTypes.func.isRequired,
  siteType: PropTypes.string.isRequired,
};

export default DeleteSiteModal;
