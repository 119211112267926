import { css } from '@emotion/react';
import { useMediaQueryState } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useRef,
} from 'react';

import FullNavigation from './FullNavigation';
import HolidayMobileNavigation from './HolidayMobileNavigation';
import HolidayNavigation from './HolidayNavigation';
import MobileNavigation from './MobileNavigation';
import { SITE_TYPES } from '../../core/constants';
import navigationLinks from '../../core/dashboard/navigationLinks';
import useElementProp from '../../core/hooks/useElementProp';
import useScrollY from '../../core/hooks/useScrollY';

const propTypes = {
  isMultiSiteDashboard: PropTypes.bool,
  isWedding: PropTypes.bool,
};

const SiteNavigation = ({
  siteType,
}) => {
  const ref = useRef();

  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: 'medium',
  });
  const isMobile = mediumMediaQueryState === 'BELOW';

  const {
    isScrolledBelow,
  } = useScrollY(32);

  const elementHeight = (
    useElementProp({
      propName: 'offsetHeight',
      ref,
    })
  );

  return (
    <Fragment>
      <div
        css={
          css`
            height: ${elementHeight}px;
          `
        }
      />

      <div
        css={
          css`
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 11;
          `
        }
        ref={ref}
      >
        {
          isMobile
            ? (
              !siteType || siteType === SITE_TYPES.HOLIDAY
                ? (
                  <HolidayMobileNavigation
                    isMultiSiteDashboard={!siteType}
                  />
                )
                : (
                  <MobileNavigation
                    navigationLinks={navigationLinks}
                  />
                )
            )
            : (
              !siteType || siteType === SITE_TYPES.HOLIDAY
                ? (
                  <HolidayNavigation
                    isMultiSiteDashboard={!siteType}
                  />
                )
                : (
                  <FullNavigation
                    isCollapsed={isScrolledBelow}
                    navigationLinks={navigationLinks}
                  />
                )
            )
        }
      </div>
    </Fragment>
  );
};

SiteNavigation.propTypes = propTypes;

export default SiteNavigation;
