import { SITE_TYPES } from './core/constants';

export const getBaseUrl = (siteId) => `/site/${siteId}`;

const holidaySiteRoutes = [
  {
    children: [
      {
        name: 'edit',
        path: '/edit',
      },
    ],
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    name: 'settings',
    path: '/settings?:delete',
  },
];

const weddingSiteRoutes = [
  {
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    name: 'designs',
    path: '/designs?:source',
  },
  {
    name: 'settings',
    path: '/settings?:delete',
  },
  {
    children: [
      {
        name: 'all',
        path: '/all',
      },
      {
        name: 'rsvp',
        path: '/rsvp?:eventId',
      },
    ],
    name: 'guests',
    path: '/guests',
  },

  // Pages nested routes
  {
    children: [
      {
        name: 'home',
        path: '/home',
      },
      {
        name: 'rsvp',
        path: '/rsvp',
      },
      {
        name: 'events',
        path: '/events',
      },
      {
        name: 'registry',
        path: '/registry',
      },
      {
        name: 'photos',
        path: '/photos',
      },
      {
        name: 'accommodations',
        path: '/accommodations',
      },
      {
        name: 'wedding-party',
        path: '/wedding-party',
      },
      {
        name: 'guest-book',
        path: '/guest-book',
      },
      {
        name: 'custom',
        path: '/:id/:slug',
      },
      {
        name: 'edit',
        path: '/edit',
      },
    ],
    name: 'pages',
    path: '/pages',
  },
];

const onlineInvitationSiteRoutes = [
  {
    name: 'settings',
    path: '/settings?:delete',
  },
  {
    name: 'guests',
    path: '/guests',
  },
  // Pages nested routes
  {
    children: [
      {
        name: 'home',
        path: '/home',
      },
      {
        name: 'events',
        path: '/events',
      },
      {
        name: 'registry',
        path: '/registry',
      },
      {
        name: 'custom',
        path: '/:id/:slug',
      },
      {
        name: 'edit',
        path: '/edit',
      },
    ],
    name: 'pages',
    path: '/sections',
  },
];

export const routesBySiteType = {
  [SITE_TYPES.WEDDING]: weddingSiteRoutes,
  [SITE_TYPES.ONLINE_INVITATION]: onlineInvitationSiteRoutes,
  [SITE_TYPES.HOLIDAY]: holidaySiteRoutes,
};
